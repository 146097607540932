import store from '@/store';
import { apiService } from './ApiService';
import { setCustomerDetails } from '@/store/features/customerSlice';
import { getCookie } from 'cookies-next';

const getCustomerDetails = async () => {
  if (!getCookie('woodenstreetUserAuthToken')) {
    localStorage.removeItem('customerDetails');
    return { code: 500 };
  }
  const response: any = await apiService.get(`getDetails`, '', '', true);
  if (Object.keys(response?.data).length > 0) {
    const details = JSON.stringify(response.data.details);
    if (Object.keys(response?.data?.details).length > 0)
      localStorage.setItem('customerDetails', details);
    else localStorage.removeItem('customerDetails');
    store.dispatch(setCustomerDetails(response.data.details));
  }
  return response;
};
const updateCustomerDetails = async (data: any) => {
  const response: any = await apiService.put(`updateCustomer`, '', data, true);
  if (Object.keys(response.data).length > 0) getCustomerDetails();
  // store.dispatch(setCustomerDetails(response.data));
  return response;
};
const updateCustomerPassword = async (data: any) => {
  const response: any = await apiService.put(
    `updateCustomerPassword`,
    '',
    data,
    true
  );
  if (Object.keys(response.data).length > 0) getCustomerDetails();
  return response;
};

const resetCustomerPassword = async (data: any) => {
  const response: any = await apiService.put(
    `updateCustomerPassword`,
    '',
    data,
    true
  );
  return response;
};

export const customerService = {
  getCustomerDetails,
  updateCustomerDetails,
  updateCustomerPassword,
  resetCustomerPassword,
};
