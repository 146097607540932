import store from '@/store';
import { apiService } from './ApiService';
import { setWishlistDetails } from '@/store/features/wishListsSlice';
import { getCookie } from 'cookies-next';

const getWishlist = async () => {
  if (!getCookie('woodenstreetUserAuthToken')) {
    store.dispatch(setWishlistDetails({}));
    return { code: 500 };
  }
  const response: any = await apiService.get(
    `getWishlistProducts`,
    '',
    '',
    true
  );
  if (Object.keys(response?.data).length > 0) {
    store.dispatch(setWishlistDetails(response.data));
  }
  return response;
};
const addWishlist = async (data: any) => {
  if (!getCookie('woodenstreetUserAuthToken')) return { code: 505 };
  const response: any = await apiService.post(`addWishlistProduct`, data, true);
  return response;
};
const removeWishlist = async (data: any) => {
  if (!getCookie('woodenstreetUserAuthToken')) return { code: 505 };
  const response: any = await apiService.post(
    `removeWishlistProduct`,
    data,
    true
  );
  return response;
};

export const wishlistService = {
  getWishlist,
  removeWishlist,
  addWishlist,
};
